//

export default function Empowerment() {
  return (
    <div className="empowerment-banner">
      <div className="container">
        <div>
          Our Founder, Bode Pedro, is C-Suite Insider's CEO of the Year 🎉.{' '}
          <a
            href="https://www.c-suiteinsider.com/magazine/"
            target="_blank"
            rel="noreferrer"
          >
            Read more here
          </a>
        </div>
      </div>
    </div>
  );
}
